import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = () => {
  const FORM_ENDPOINT = "/contact/#"
  const [submitted, setSubmitted] = useState(false)
  const handleSubmit = () => {
    setTimeout(() => {
      setSubmitted(true)
    }, 100)
  }

  return (
    <Layout>
      <SEO
        keywords={[`development`, `javascript`, `react`, `tailwindcss`]}
        title="Press"
      />
      <section className="max-w-screen-lg m-auto  mt-20 px-5">
        <h1 className="text-center p-3 mt-5 mb-4 text-2xl font-bold text-white">
          Press Releases
        </h1>

        <div className="flex max-w-2xl mb-3 mx-auto bg-white rounded-lg shadow-md dark:bg-gray-800">
          <div className="p-4">
            <div className="w-2/3 flex items-center justify-between">
              <span className="text-sm font-light text-gray-600 dark:text-gray-400">
                Monday June 4, 2018
              </span>
            </div>

            <div className="mt-2">
              <a
                href="http://www.prnewswire.com/news-releases/squirreled-world-educational-ios-game-combines-learning-with-fun-300659023.html?tc=eml_cleartime"
                className="text-lg font-bold text-gray-700 dark:text-white hover:text-gray-600 dark:hover:text-gray-200 hover:underline"
              >
                Squirreled® World Educational iOS Game Combines Learning with
                Fun!
              </a>
            </div>

            <div className="flex items-center justify-between mt-1"></div>
            <a
              href="http://www.prnewswire.com/news-releases/squirreled-world-educational-ios-game-combines-learning-with-fun-300659023.html?tc=eml_cleartime"
              className="ext-sm text-blue-600 dark:text-blue-400 hover:underline"
            >
              View Press Release
            </a>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ContactPage
